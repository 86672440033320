import { appWithTranslation } from "next-i18next";
import React from "react";

import { trpc } from "@calcom/trpc/react";

import type { AppProps } from "@lib/app-providers";

import nextI18NextConfig from "../next-i18next.config.js";
import "../styles/custom.css";
import "../styles/fonts.css";
import "../styles/globals.css";

function MyApp(props: AppProps) {
  const { Component, pageProps } = props;
  if (Component.PageWrapper !== undefined) return Component.PageWrapper(props);
  return <Component {...pageProps} />;
}

// export default appWithTranslation(trpc.withTRPC(MyApp), nextI18NextConfig);
export default trpc.withTRPC(appWithTranslation(MyApp, nextI18NextConfig));
